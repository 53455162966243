import $ from 'jquery'

class AjaxFormWidget {
    constructor(formName, typeForm, idObject = '') {
        this.formName = formName
        this.typeForm = typeForm
        this.idObject = idObject

        // Формируется id формы из типа формы(new, create) и ее названия
        if (this.idObject.length > 0) {
            this.idObject = '_' + this.idObject
        }

        this.formBlock = '[id^=' + this.typeForm + '_' + this.formName + ']'

        this.errorLabel = 'custom_error' // Сообщение об ошибке
        this.errorInput = 'error'  // Поле с ошибкой

        this.formBlockSelector = $(this.formBlock)
        this.errorLabelSelector = $('.' + this.errorLabel)
        this.errorInputSelector = $('.' + this.errorInput)

        this.formBlockSelector.bind('ajax:error', (e) => {
            this.formError(e)
        })
        this.formBlockSelector.on('ajax:success', (e) => {
            this.formSuccess(e)
        })
    }

    formError = (e) => {
        const data = e.detail[0] // массив с ошибками
        // const status = e.detail[1] // строка статуса ответа от сервера
        // const xhr = e.detail[2] // данные запроса
        if(data.errorServer){
            $('.popup-wrapper--error-server').addClass('active')
        }

        if(data.result == '5'){
            $('.popup-wrapper--not-found').addClass('active')
        }

        this.removeErrors();
        for(var field in data) {
            const fieldValue = data[field];

            let errorInput = $($('#' + this.formName + '_' + field).parents('.input-field')[0]);
            errorInput.addClass(this.errorInput);

            if(Array.isArray(fieldValue)) {
                fieldValue.forEach((mes, i, arr) => {
                    $('#' + this.formName + '_' + field).after('<span class="helper-text" data-error="' + mes + '"></span>')
                })
            }
        }

    }

    formSuccess = (e) => {
        this.removeErrors()
        const data = e.detail[0] // массив с ошибками
        // const status = e.detail[1] // строка статуса ответа от сервера
        // const xhr = e.detail[2] // данные запроса

        if(data['location']) {
            this.successRedirect(data['location'])
        }

        if(data['redirect_url']) {
            this.paymentRedirect(data['redirect_url'])
        }
    }

    removeErrors = () => {
        $('.' + this.errorLabel).remove()
        $('.' + this.errorInput).removeClass(this.errorInput);
        $('.helper-text').remove()
    }

    successRedirect = (location) => {
        const redirectUrl = new URL(window.location.protocol + "//" + window.location.host + location)
        window.location.href = redirectUrl
    }

    paymentRedirect = (redirect_url) => {
        const redirectPaymentUrl = new URL(redirect_url);
        window.location.href = redirectPaymentUrl;
    }
}

export default AjaxFormWidget