// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import 'materialize-css/dist/js/materialize'
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from  "jquery"
import AjaxFormWidget from "../base/ajax_form.js"

Rails.start();
Turbolinks.start();
ActiveStorage.start();


$(document).ready(function () {
    new AjaxFormWidget('user', 'new');
    new AjaxFormWidget('payment', 'new');

    $('.popup-page__close-button').click(function () {
        $('.popup-page-wrapper').removeClass('active');
        $('body').removeClass('not_scroll');
        $('header').removeClass('fixed');
        $('.popup-page__text').html('');
    });

    $('.js-document-link, .js-header-left__menu-link').click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        var link = $(this).attr('href');
        $('.popup-page-wrapper').addClass('active');
        $('body').addClass('not_scroll');
        $('header').addClass('fixed');

        if(link){
            $.ajax({
                url: link,
                type: "GET",
                dataType: 'html',
                success: function (data, status, xhr) {
                    $('.popup-page__text').html(data);
                },

                error: function (data) {
                    console.log(data)
                }
            });
        }
    });

    $('.popup-page__print-button').click(function () {
        var printContents = $($('.popup-page')[0]).html();
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();
        document.body.innerHTML = originalContents;

    });

    $('.input-field input').focusin(function () {
        $($(this).parents('.input-field')[0]).addClass('active')
    });

    $('.input-field input').focusout(function () {
        $($(this).parents('.input-field')[0]).removeClass('active')
    });

    $('.header__open-menu-button').click(function () {
        $('.header__popup-menu-wrapper').addClass('active');
        $('.header__open-menu-button').removeClass('active');
        $('.header__close-menu-button').addClass('active');
    });

    $('.header__close-menu-button').click(function () {
        $('.header__popup-menu-wrapper').removeClass('active');
        $('.header__open-menu-button').addClass('active');
        $('.header__close-menu-button').removeClass('active');
    });

    $('.popup-block__close-button').click(function () {
        $('.popup-wrapper').removeClass('active');
    });

    $('.payment-page__info-toggle-button').click(function () {
        $('.payment-page__info-toggle-block').toggleClass('active');
        $('.payment-page__info-toggle-button').toggleClass('active');
    });
});